<template>
  <div>
    <CContainer>
      <CRow>
        <CCol md="6">
          <CCardGroup>
            <CCard>
              <CCardHeader>
                <strong v-if="$route.params.id">
                  Редактирование вопроса
                </strong>
                <strong v-else>
                  Создание вопроса
                </strong>
              </CCardHeader>
              <CCardBody>
                <CForm @submit.prevent="handleSubmit">
                  <CTextarea
                    v-model="text"
                    label="Введите текст вопроса"
                    placeholder="Текст вопроса"
                  >
                  </CTextarea>
                  <CRow>
                    <CCol>
                      <CButton
                        v-if="$route.params.id"
                        color="success"
                        type="submit"
                      >
                        Сохранить
                      </CButton>
                      <CButton
                        v-else
                        color="success"
                        type="submit"
                      >
                        Создать
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: 'QuestionsForm',
  data () {
    return {
      text: null,
    }
  },
  computed: {
    ...mapState({
      question: (state) => state.questions.all.question
    }),
  },
  created() {
    if (this.$route.params.id) {
      this.getById(this.$route.params.id);
    }
  },
  methods: {
    ...mapActions("questions", ["create", "update", "getById"]),
    handleSubmit () {
      let formData = {
        "text": this.text
      };
      if (this.$route.params.id) {
        formData.id = this.$route.params.id;
        this.update(formData);
      } else {
        this.create(formData);
      }
    }
  },
  watch: {
    question: function (val, oldVal) {
      if (val) {
        this.text = val.text;
      }
    }
  }
}
</script>
